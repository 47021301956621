<template>
  <div className="app-calendar overflow-hidden border">
    <div className="row no-gutters">

      <!-- Calendar -->
      <div className="col position-relative">
        <div className="card shadow-none border-0 mb-0 rounded-0">
          <div className="card-body pb-0">
            <treasure-calendar default-type="month"
                               :currentMonth.sync="currentMonth"
                               v-bind:types="selectedCalendarsIds"
                               @event-selected="onEventSelected"
                               :property-id="propertyId"/>
          </div>
        </div>
      </div>

<!--      &lt;!&ndash; Sidebar Overlay &ndash;&gt;
      <div className="body-content-overlay" :class="{'show': isCalendarOverlaySidebarActive}"
           @click="isCalendarOverlaySidebarActive = false"/>
      <calendar-event-handler v-model="isEventHandlerSidebarActive" v-bind:event-id="event.id"
                              @created="id=>event.id=id" :clear-event-data="clearEventData"/>-->
    </div>
  </div>
</template>

<script>
import TreasureCalendar from "@/views/calendar/treasureCalendar";


export default {
  name: "calendarRent",
  components: {
    TreasureCalendar,
  },
  data() {
    return {
      isCalendarOverlaySidebarActive: false,
      isEventHandlerSidebarActive: false,
      currentMonth: null,
      selectedCalendarsIds: [],
      event: {id: 0},
    }
  },
  props: {
    propertyId: {
      type: String,
      required: true,
    }
  },
  computed: {
  },
  watch: {
    isCalendarOverlaySidebarActive() {
      this.$root.$emit('bv::hide::popover')
    },
  },
  methods: {
    addNewEvent() {
      this.$root.$emit('bv::hide::popover')
      this.event['start'] = null;
      this.event['id'] = 0;
      this.isEventHandlerSidebarActive = true;
    },
    onDaySelected(day) {
      this.$root.$emit('bv::hide::popover')
      this.event['start'] = day;
      this.event['id'] = 0;
      this.isEventHandlerSidebarActive = true;
    },
    onEventSelected(eventId) {
      this.$root.$emit('bv::hide::popover')
      this.event['start'] = null;
      this.event['id'] = eventId;
      this.isEventHandlerSidebarActive = true;
    },
    clearEventData() {

    }
  }

}
</script>

<style lang="scss">
@import "@core/scss/vue/apps/calendar.scss";
</style>
