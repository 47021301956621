<template>
  <div class="calendar">
    <FullCalendar ref="calendar" v-bind:options="calendarOptions"/>
    <template v-if="dialogs.popovers">
      <b-popover v-for="event in events" v-bind:key="event.id" :ref="'popover_cal_'+event.id" :target="'ev-'+event.id"
                 @show=" $root.$emit('bv::hide::popover');"
                 triggers="click focus" size="xl"
                 style="max-width: none">
        <template slot="title">
          <b-row>
            <b-col cols="9">
              {{ event.title }}
            </b-col>
            <b-col cols="3">
              <div class="d-flex align-items-center justify-content-end">

                <span class="mr-1"></span>
                <b-link style="color: inherit;" @click="closePopover" :title="$t('Close')">
                  <feather-icon icon="XIcon"/>
                </b-link>
              </div>
            </b-col>
          </b-row>

        </template>
        <div :temp="'ev-'+event.id">
          <div class="event-popover-content">
            <treasureCalendarEvent :event="event" @clientSelect="onClientSelect"/>
          </div>
        </div>
      </b-popover>
    </template>
  </div>
</template>
<script>

import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import allLocales from '@fullcalendar/core/locales-all'
import {mapActions} from 'vuex'
import listPlugin from '@fullcalendar/list'
import treasureCalendarEvent from "@/views/calendar/treasureCalendarEvent";

export default {
  components: {
    FullCalendar,
    // eslint-disable-next-line vue/no-unused-components
    treasureCalendarEvent
  },
  data() {
    return {
      calendarOptions: {
        displayEventTime: false,
        showNonCurrentDates: true,
        locales: allLocales,
        locale: 'de',
        plugins: [dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin],
        headerToolbar: {
          start: 'sidebarToggle, prev,next, title',
          end: 'dayGridMonth,listMonth',
        },
        initialView: 'dayGridMonth',
        dateClick: this.onDateClick,
        editable: true,
        eventDrop: this.moveEvent,
        events: this.onCalendarRefresh,
        eventClick: this.onEventClick,
        eventDidMount: this.onEventDidMount,
        eventRender: this.eventRender
      },

      events: [],
      cal: {start: '', end: ''},
      loadedExternalEvents: {},
      dialogs: {
        popovers: false
      },
      listeners: {
        refresh() {
        }
      }
    }
  },
  props: {
    types: Array,
    propertyId: {
      type: String,
      required: true,
    },
  },
  watch: {
    types: {
      handler() {
        console.log('types watcher triggered');
        this.refreshCalendar()
      },
      // immediate: true
    },
  },
  created() {
    console.log('calendar created and listener attached');
    let $this = this;
    this.$root.$on('crm::calendar::refresh', this.listeners.refresh = function () {
      $this.refreshCalendar()
    })
  },
  beforeDestroy() {
    this.$root.$off('crm::calendar::refresh')
  },
  computed: {},
  methods: {
    ...mapActions('calendar', ['getTreasureCalendars']),

    refreshCalendar() {
      console.log('refreshCalendar')
      let calendarApi = this.$refs.calendar.getApi()
      calendarApi.refetchEvents()

    },
    onCalendarRefresh(info, successCallback, failureCallback) {
      console.log('onCalendarRefresh')
      this.cal.start = this.$moment(info.start).startOf('month')
          .format('YYYY-MM-DD  HH:mm')
      this.cal.end = this.$moment(info.end).endOf('month')
          .format('YYYY-MM-DD  HH:mm')
      this.refresh()
          .then(() => successCallback(this.adaptEvents()), failureCallback)

    },
    refresh() {
      let $this = this
      return new Promise((resolve, reject) => {
        this.getTreasureCalendars({
          from: $this.cal.start,
          until: $this.cal.end,
          userid: -1,
          typeList: [6],
          propertyId: this.propertyId
        })
            .then(
                (events) => {
                  $this.updateEvents(events)
                  resolve()
                },
                reject)
      })
    },
    updateEvents(apiEvents) {
      console.log("updateEvents")

      if (!Array.isArray(apiEvents)) {
        apiEvents = []
      }

      this.events = apiEvents.map(e => ({...e, external: false}));
      console.log("updateEvents", this.events)

      return this.events
    },
    adaptEvents() {
      console.log("adaptEvents");
      return this.events.map(ev => ({
        start: ev.start,
        end: ev.end,
        title: ev.title,
        id: 'ev-' + ev.id,
        rawId: ev.id,
        textColor: 'black',
        color: this.getColor(ev),
      }))
    },
    eventRender(info) {
      // Create a custom event element
      const eventElement = document.createElement('div');
      eventElement.classList.add('custom-event');

      // Create an element for the event icon
      const iconElement = document.createElement('img');
      iconElement.src = info.event.extendedProps.icon;

      // Create an element for the event title
      const titleElement = document.createElement('span');
      titleElement.textContent = info.event.title;

      // Append the icon and title to the custom event element
      eventElement.appendChild(iconElement);
      eventElement.appendChild(titleElement);

      // Replace the event's element with the custom event element
      info.el.innerHTML = '';
      info.el.appendChild(eventElement);
    },

    getEventClassNames(event) {

      if (event == null || event.platform == null)
        return 'ev-treasure-home';

      let platform = event.platform;
      // Add logic to determine the appropriate CSS class for the event
      if (platform === 'Airbnb') {
        return 'ev-airbnb';
      } else if (platform === 'Booking') {
        return 'ev-booking';
      } else if (platform === 'Agoda') {
        return 'ev-agoda';
      } else if (platform === 'Expedia') {
        return 'ev-expedia';
      } else if (platform === 'Vrbo') {
        return 'ev-vrbo';
      } else if (platform === 'Treasure Home') {
        return 'ev-treasure-home';
      } else {
        return ''; // Default class, no icon
      }
    },
    getColor(event) {
      if (event == null  || event.platform == null)
        return 'rgba(199,166,128,0.71)';

      let platform = event.platform;
      if (platform === 'Booking')
        return "rgba(49,121,231,0.73)"

      if (platform === 'Expedia')
        return "rgba(239,221,9,0.71)"

      if (platform === 'Airbnb')
        return "rgba(255,82,88,0.79)"

      if (platform === 'Vrbo')
        return "#dfe6e9"

      if (platform === 'Agoda')
        return "#2ecc71"

      if (platform === 'Treasure Home')
        return "#c7a680"

      if (platform === 'Unknown')
        return "rgba(236,228,207,0.89)";


    },
    eventsFilter(ev) {
      return (!Array.isArray(this.types) || this.types.includes(ev.type));
    },

    onEventDidMount: function (eventInfo) {
      let $this = this;
      eventInfo.el.id = eventInfo.event.id

      eventInfo.el.ondblclick = function () {
        $this.editEvent(eventInfo.event.extendedProps.rawId)
      }
      this.refreshPopovers()
    },
    refreshPopovers() {
      let $this = this
      //Hack in order to reload the popovers
      this.dialogs.popovers = false
      setTimeout(() => {
        this.dialogs.popovers = true
        $this.$forceUpdate()
      }, 1000)
    },
    onDateClick(arg) {
      this.$emit('day-selected', arg.dateStr)
    },
    moveEvent(info) {
      let eventInfo = info.event
      if (!confirm('Are you sure about this change?')) {
        info.revert()
        return
      }
      let event = this.events.find(e => e.id === parseInt(eventInfo.id.substr(3)))
      if (event == null) {
        info.revert()
        return
      }
      event = {...event}
      event.start = this.$moment(eventInfo.start)
          .format('YYYY-MM-DD HH:mm')
      event.end = this.$moment(eventInfo.end)
          .format('YYYY-MM-DD HH:mm')
      this.update({
        id: event.id,
        event: event
      })
      this.refreshPopovers()
    },
    editEvent(id) {
      this.$emit('event-selected', id)
      this.closePopover()
    },
    onEventClick(eventInfo) {
      //this.editEvent(eventInfo.event)
      this.$root.$emit('bv::show::popover', 'ev' + eventInfo.event.id)
    },
    closePopover(popover) {
      if (popover != null && typeof popover === 'string') {
        this.$root.$emit('bv::hide::popover', popover)
      } else {
        this.$root.$emit('bv::hide::popover')
      }

    },
    onClientSelect(id) {
      console.log('calendar-> onClientSelect(' + id + ')')
      this.$root.$emit('crm::client::select', id)
    },

  }
}
</script>

<style>
.event-popover-content {
  max-width: none;
  width: 400px;
}


/*
.fc-daygrid .fc-day-other {
  background-color: rgba(241, 145, 145, 0.24);
}
*/


.fc-event:hover {
  transform: scale(1.1);
  transition: transform 0.3s;
}

.fullcalendar-event {
  position: relative; /* Ensure relative positioning for event container */
  padding-left: 30px; /* Add some left padding for the icon */
  background-repeat: no-repeat; /* Prevent background image from repeating */
  background-position: left center; /* Position the image on the left-center */
  background-size: 20px 20px; /* Adjust the size of the icon */
}

/* Define specific background images for each event */
.ev-airbnb {
  background-image: url('https://treasurehome.at/uploads/images/airbnb.png'); /* Replace with your Booking.com logo image URL */
}

.ev-booking {
  background-image: url('https://treasurehome.at/uploads/images/booking.png'); /* Replace with your Booking.com logo image URL */
}

.ev-expedia {
  background-image: url('https://treasurehome.at/uploads/images/expedia.png'); /* Replace with your Booking.com logo image URL */
}

.ev-treasure {
  background-image: url('https://treasurehome.at/uploads/images/treasurehome.png'); /* Replace with your Booking.com logo image URL */
}

.ev-vrbo {
  background-image: url('https://treasurehome.at/uploads/images/vrbo.png'); /* Replace with your Booking.com logo image URL */
}

.ev-agoda {
  background-image: url('https://treasurehome.at/uploads/images/agoda.png'); /* Replace with your Booking.com logo image URL */
}

.custom-event {
  background-color: #007bff; /* Set the background color */
  color: #fff; /* Set the text color */
  padding: 5px 10px; /* Add padding to the custom event */
  border-radius: 20px; /* Set border-radius to create rounded corners */
  display: flex; /* Use flex to align icon and title horizontally */
  align-items: center; /* Vertically center the icon and title */
}

/* Define styles for the event icon */
.custom-event img {
  width: 20px; /* Adjust the width of the icon */
  height: 20px; /* Adjust the height of the icon */
  margin-right: 10px; /* Add space between icon and title */
}
</style>
