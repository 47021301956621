<template>
  <div class="event-details" v-if="pageLoaded">

    <template v-if="!isExternal">
      <!-- Header: Administrative Info -->
      <div class="d-flex my-1">
        <feather-icon icon="CalendarIcon" size="19"/>
        <h4 class="mb-0 ml-50">
          {{ $t('Date') }}
        </h4>
      </div>
      <hr/>
      <b-row class="">
        <b-col cols="1" v-b-tooltip.hover :title="$t('Start')">
          <i class="far fa-clock"></i>
        </b-col>
        <b-col cols="5">
          <p class="event-details-description capitalize">{{ event.start }}</p>
        </b-col>
        <b-col cols="1" v-b-tooltip.hover :title="$t('End')">
          <i class="fas fa-clock"></i>
        </b-col>
        <b-col cols="5">
          <p class="event-details-description capitalize">{{ event.end }}</p>
        </b-col>
        <template v-if="event.platform != null">
          <b-col cols="3">
            <b-img :src="platformLogoUrl" alt="Platform Logo"/>
          </b-col>
        </template>

      </b-row>

    </template>

    <!-- Header: Administrative Info -->
    <template v-if="event.realEstateRentContract!=null ">
      <div class="d-flex my-1 mt-4">
        <feather-icon icon="UserIcon" size="19"/>
        <h4 class="mb-0 ml-50">
          {{ $t('Client') }}
        </h4>
      </div>
      <hr/>
      <b-row class="12">
        <b-col cols="1" v-b-tooltip.hover :title="$t('Phone')">
          <i class="fas fa-phone"></i>
        </b-col>
        <b-col cols="11" class="mb-3">
          <p class="event-details-description capitalize">{{ event.realEstateRentContract.client.phone }}</p>
        </b-col>
        <b-col cols="1" v-b-tooltip.hover :title="$t('Email')">
          <i class="fas fa-mail-bulk"></i>
        </b-col>
        <b-col cols="11">
          <p class="event-details-description capitalize">{{ event.realEstateRentContract.client.email }}</p>
        </b-col>
      </b-row>

    </template>
    <!-- Header: Administrative Info -->
    <div class="d-flex my-1" v-if="event.eventId != null && event.eventId.trim().length > 0">
      <feather-icon icon="AlignJustifyIcon" size="19"/>
      <h4 class="mb-0 ml-50">
        {{ $t('Contract') }}
      </h4>
    </div>
    <hr/>
    <div v-if="event.eventId != null && event.eventId.trim().length > 0">
      <b-badge @click="routeToContract(event.eventId)" class="event-details-description badge">
        {{ $t('redirectToContract') }}
      </b-badge>
    </div>
  </div>
  <div class="event-details" v-else-if="error !== ''">
    <b-alert show variant="danger"><i class="fas fa-exclamation-triangle mr-3"></i> <strong>{{ error }}</strong>
    </b-alert>
  </div>
  <div class="event-details" v-else>
    Loading
  </div>
</template>

<script>
import {date} from '../../../global/helper'
import {mapActions, mapState} from 'vuex'

export default {
  name: 'treasureCalendarEvent',
  data: () => ({
    platformLogos: {
      'Vrbo': 'https://treasurehome.at/uploads/images/vrbo.png',
      'Booking': 'https://treasurehome.at/uploads/images/booking.png',
      'Airbnb': 'https://treasurehome.at/uploads/images/airbnb.png',
      'Treasure Home': 'https://treasurehome.at/uploads/images/treasurehome.png',
      'Agoda': 'https://treasurehome.at/uploads/images/agoda.png',
      'Expedia': 'https://treasurehome.at/uploads/images/expedia.png',
    },
    loaded: false,
    error: '',
    clients: [],
    //Listeners
    listeners: {
      load: () => {
      }
    }
  }),
  created() {
    this.reloadClients();
  },

  props: {
    event: Object,
    external: {
      default: false,
      type: Boolean
    }
  },
  computed: {
    ...mapState({
      data: function (store) {
        return store.data
      }
    }),
    platformLogoUrl() {
      if (this.event.platform() != null) {
        const platformName = this.event.platform;
        console.log("platform name: ", platformName);
        let link = this.platformLogos[platformName];
        console.log("platform link: ", link);
        return link;
      }
      return '';
    },
    isExternal() {
      return this.external === true
    },
    hasUsers() {
      return this.getUsers.length > 0;
    },
    hasClients() {
      return this.clients.length > 0
    },
    hasOnlyOneClient() {
      return this.hasClients && this.clients.length === 1
    },
    uniqueClient() {
      if (!this.hasOnlyOneClient) {
        return {}
      }
      return this.clients[0]
    },

    pageLoaded() {
      return this.event != null && typeof this.event === 'object'
    },
    eventColor() {
      if (this.event == null || this.event.color == null) {
        return '#1867c0'
      }
      return this.event.color
    },
    eventTime() {
      if (this.event == null || this.event.date == null) {
        return 'Date is null'
      }
      return date.dateStringFormated(this.event.date, true)
    }
  },
  methods: {
    ...mapActions('calendar', ['get']),
    ...mapActions('clients', ['getClients']),
    load() {

      let $this = this
      $this.loaded = false
      console.log('load calendar: ' + event.title)
      this.get({id: $this.event.id})
          .then((event) => {
            if (event == null) {
              return $this.error = 'Load event failed'
            }
            $this.reloadClients();
            $this.loaded = true
          }, (error) => {
            $this.error = error
          })
    },
    routeToContract(eventId) {
      this.$router.push(`/contract/view/${eventId}`);
    },
    reloadClients() {
      let $this = this;
      if (this.event.clientsIds == null || this.event.clientsIds.length <= 0) {
        this.clients = [];
        return
      }
      this.getClients({filter: {ids: this.event.clientsIds}}).then(
          (clientData) => $this.clients = clientData['clients'].map(client => {
            let fn = client.firstname != null ? client.firstname.trim() : '';
            let ln = client.lastname != null ? client.lastname.trim() : '';
            let acronym = (fn !== "" ? fn.charAt(0).toUpperCase() : "") + (ln !== "" ? ln.charAt(0).toUpperCase() : "");
            return {id: client.id, name: fn + ' ' + ln, acronym}
          })
      )
    },
    onClientSelect(id) {
      console.log('CalendarEvent -> onClientSelect', id)
      this.$root.$emit('crm::client::select', id)
    },
  }
}
</script>

<style scoped>

p {
  margin: 0;
}

.event-details .event-details-title {
  text-transform: capitalize;
  line-height: 28px;
  font-family: 'Google Sans', Roboto, Arial, sans-serif;
  font-size: 22px;
  font-weight: 400;
  color: #3c4043;
}

.event-details .event-details-description {
  font-family: Roboto, Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.2px;
  line-height: 20px;
  color: #3c4043;
}

.event-details .capitalize {
  text-transform: capitalize;

}

.event-details .event-details-description ul {
  margin: 0;
  padding: 0;
}

.event-details .event-details-description ul > li {
  list-style: none;
}
</style>
<style>
.event-details .ps .ps__rail-x,
.event-details .ps .ps__rail-y {
  opacity: 1;
}

.event-details .ps__rail-y > .ps__thumb-y {
  width: 11px;
}

.event-details .ps .ps__rail-y {
  background-color: #eee;
  opacity: 0.9;
}

.badge {
  display: inline-block;
  padding: 5px 10px;
  color: #fff;
  border-radius: 5px;
  cursor: pointer; /* Set cursor to pointer */
}

.badge:hover {
  background-color: #f3a152; /* Change background color on hover */
}

</style>
